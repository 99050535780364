<template>
	<div>
				<div class="row">
					<div class="col-lg-12">
						<div class="card">
							<div class="card-body">
								<h4 class="card-title">Library</h4>
								<div class="table-responsive">
									<table class="table table-striped table-bordered verticle-middle">
										<thead>
											<tr>
												
												<th scope="col"></th>
												<th scope="col">Game Name</th>
												<th scope="col">Type</th>
												<th scope="col">Last time rented</th>
												<th scope="col">Last Modification</th>
											</tr>
										</thead>
										<tbody>
											<tr v-for="(item, k) in games" :key="k">
												<td class="col-sm-1 text-center" style="padding: 3px;">
													<img v-bind:src="item.thumbnail" class="m-r-10" style="border-radius: 20%; height: 70px;" alt="">
												</td>
												<td>{{ item.name }}
												</td>
												<!-- <td>
													<span class="f-s-13">Cras sit amet nibh libero, in gravida nulla.</span>
												</td> -->
												<td>
													<label class="label label-primary">{{item.mainCategory}}</label>
													<label class="label label-info" v-for="cat in item.subCategories" :key="cat">{{cat}}</label>
												</td>
												<td class="col-sm-2">{{ item.lastRented ? timeAgo(item.lastRented) : 'Never' }}</td>
												<td class="col-sm-2">{{ timeAgo(item.lastChange) }}</td>
												<!-- <td><i class="fa fa-circle-o text-success f-s-12 m-r-10"></i> Active</td>
												<td>
													<div class="progress h-6px">
														<div role="progressbar" class="progress-bar bg-primary w-50pc h-6px"></div>
													</div>
												</td>
												<td><span><a href="#" data-toggle="tooltip" data-placement="top" title="Edit"><i class="fa fa-pencil color-muted m-r-5"></i> </a><a href="#" data-toggle="tooltip" data-placement="top" title="Close"><i class="fa fa-close color-danger"></i></a></span>
												</td> -->
											</tr>
										</tbody>
									</table>
								</div>
							</div>
						</div>
					</div>
				</div>                
			</div>
		<!-- </div> -->
			
</template>

<script>
import { DateTime } from 'luxon';
import { API as AmplifyApi } from 'aws-amplify';
const apiName = 'main';
const timeUnits = [
	'year','month','week','day','hour','minute','second'
]

export default {
  data: () => ({
	   count : 5,
	   games: [
	   ],
  }),
  methods: {
	  timeAgo: function(time){
		  if(!time){ return 'Unknown' }
		  const diff = DateTime.fromSeconds( parseInt(time)).diffNow().shiftTo(...timeUnits);
		  const unit = timeUnits.find((unit) => diff.get(unit) !== 0) || 'second';

		  const relativeFormatter = new Intl.RelativeTimeFormat('en', { numeric: 'auto' });
		  return relativeFormatter.format(Math.trunc(diff.as(unit)), unit);
	  },
	  fetchGames: async function(){
		  const vm = this;
		  const resp = await AmplifyApi.get(apiName, 'games/list');
		  console.log(resp);
		  vm.games = resp.list;
	  }
  },
  mounted () {
	  this.fetchGames();
  },
}
</script>