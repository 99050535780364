<template>
  <div>
    <div class="row">
      <div class="col-lg-8 offset-lg-2">
        <div class="card">
          <div class="card-body">
            <div class="text-center m-t-10">
              <h2 class="m-t-15 m-b-0">Add a new game</h2>
              <p class="f-s-12">Search and BoardGameGeek to add a new game to the library.</p>
              <div class="form-group">
                <input
                  type="text"
                  v-model="query"
                  class="form-control input-rounded"
                  placeholder="Barcode"
                >
              </div>
            </div>
          </div>
        </div>
        <div class="card">
          <div class="card-body">
            <h4 class="card-title">Matching Games from BGG</h4>
            <div class="table-responsive">
              <table class="table table-striped table-bordered verticle-middle">
                <thead>
                  <tr>
                    <th scope="col">id</th>
                    <th scope="col">Game Name</th>
                    <th scope="col">Release</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(item, k) in games" :key="k" v-on:click="jump('/admin/add_game/'+item.id+'/')">
                    <td>
                      {{ item.id }}
                    </td>
                    <td>
                      <img
                        src="../../assets/images/avatar/1.jpg"
                        class="w-30px rounded-circle m-r-10"
                        alt
                      >
                      {{ item.name }}
                    </td>
                    <td>
                      {{ item.year }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- </div> -->
</template>

<script>
import { API as AmplifyApi } from 'aws-amplify'
const apiName = 'main';

export default {
  data: () => ({
    query: "",
    games: []
  }),
  watch: {
    query: function() {
	  const vm = this;
	  const querySnap = vm.query;
	  setTimeout(() => {
		  if(querySnap != vm.query){ return; }
		  vm.fetchGames(vm.query);
	  }, 500);
    }
  },
  methods: {
	fetchGames: async function(query){
		const vm = this;
		const resp = await AmplifyApi.get(apiName, 'admin/browseBGG/search/'+encodeURIComponent(query), {});
		vm.games = resp.list;
	},
	jump: function(go){
		console.log(go);
		this.$router.push({ path: go });
	}
  },
  mounted() {
    document.body.classList.add("v-light", "horizontal-nav");
  },
  destroyed() {
    document.body.classList.remove("bg-light", "login");
  }
};
</script>