<template>
	<div class="kiosk-screen">
				<div class="row">
					<div class="col-lg-3">
						<div class="card" v-on:click="$router.push('/rent')">
							<img src="../assets/images/root.webp" alt="">
							<div class="card-body">
								<div class="text-center m-t-10">
									<!-- <i class="icon-earphones f-s-50 text-success"></i> -->
									<h2 class="m-t-15 m-b-0">Manual Return</h2>
									<p class="f-s-12">Manually change the status of a rent</p>
									<div class="btn btn-danger btn-rounded p-l-30 p-r-30 m-t-5 m-b-10">
										Return
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="col-lg-3">
						<div class="card" v-on:click="$router.push('/admin/list_games')">
							<img src="../assets/images/photos/IMG_4285.jpg" alt="">
							<div class="card-body">
								<div class="text-center m-t-10">
									<!-- <i class="icon-earphones f-s-50 text-success"></i> -->
									<h2 class="m-t-15 m-b-0">List Games</h2>
									<p class="f-s-12">All games in the library</p>
									<div class="btn btn-primary btn-rounded p-l-30 p-r-30 m-t-5 m-b-10">
										Open List
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="col-lg-3">
						<div class="card" v-on:click="$router.push('/admin/add_game')">
							<img src="../assets/images/photos/IMG_4249.jpg" alt="">
							<div class="card-body">
								<div class="text-center m-t-10">
									<!-- <i class="icon-earphones f-s-50 text-success"></i> -->
									<h2 class="m-t-15 m-b-0">Add Game</h2>
									<p class="f-s-12">Use BGG & Scan a code</p>
									<div class="btn btn-warning btn-rounded p-l-30 p-r-30 m-t-5 m-b-10">
										Browse
									</div>
								</div>
							</div>
						</div>
					</div>		
					<div class="col-lg-3">
						<div class="card" v-on:click="$router.push('/admin/add_user')">
							<img src="../assets/images/photos/IMG_4223.jpg" alt="">
							<div class="card-body">
								<div class="text-center m-t-10">
									<!-- <i class="icon-earphones f-s-50 text-success"></i> -->
									<h2 class="m-t-15 m-b-0">Add User</h2>
									<p class="f-s-12">New player entered HandCon?</p>
									<div class="btn btn-success btn-rounded p-l-30 p-r-30 m-t-5 m-b-10">
										Add Now
									</div>
								</div>
							</div>
						</div>
					</div>			
				</div>                
				<div class="row">
					<div class="col-10 offset-1">
						<div class="row">
							<div class="col-lg-4">
								<div class="card">
									<div class="card-body bg-primary">
										<div class="text-center">
											<h2 class="m-t-15 text-white f-s-50">{{ count }}</h2><span class="text-white m-t-10 f-s-20">games</span>
										</div>
									</div>
								</div>
							</div>
							<div class="col-lg-4">
								<div class="card">
									<div class="card-body bg-success">
										<div class="text-center">
											<h2 class="m-t-15 text-white f-s-50">1250</h2><span class="text-white m-t-10 f-s-20">rents</span>
										</div>
									</div>
								</div>
							</div>
							<div class="col-lg-4">
								<div class="card">
									<div class="card-body bg-warning">
										<div class="text-center">
											<h2 class="m-t-15 text-white f-s-50">1250</h2><span class="text-white m-t-10 f-s-20">players</span>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			
</template>

<script>
export default {
  data: () => ({
	   count : 5
  }),
  mounted () {
	document.body.classList.add('v-light', 'horizontal-nav')
  },
  destroyed () {
	document.body.classList.remove('bg-light', 'login')
  },
}
</script>