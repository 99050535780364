<template>
  <div v-if="game">
    <div class="row">
      <div class="col-lg-8">
        <div class="card">
          <div class="card-body">
            <div class="m-t-10">
              <h2 class="m-t-15 m-b-0">{{ game.name }}</h2>
              <p class="f-s-12">{{game.description}}.</p>
              
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-4">
        <div class="card">
          <div class="card-body">
            <div class="m-t-10">
              <img v-bind:src="game.thumbnail" style="width: 100%"/>
            </div>
          </div>
        </div>
        <div class="card">
          <div class="card-body">
            <button v-if="barcode" class="btn btn-success btn-block" v-on:click="addGame();">Add game</button>
            <button v-else class="btn btn-success btn-block" disabled>Add game</button>
          </div>
        </div>
      </div>
    </div>    
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title">Details</h4>
            <div class="form-row">
              <div class="form-group col-md-3">
                  <label>Year</label>
                  <input type="text" class="form-control" disabled v-model="game.year">
              </div>
              <div class="form-group col-md-3">
                  <label>Min Players</label>
                  <input type="text" class="form-control" v-model="game.minPlayers">
              </div>
              <div class="form-group col-md-3">
                  <label>Max Players</label>
                  <input type="text" class="form-control" v-model="game.maxPlayers">
              </div>
              <div class="form-group col-md-3">
                  <label>Play time (minutes)</label>
                  <input type="text" class="form-control" v-model="game.playingTime">
              </div>
              <div class="form-group col-md-3">
                  <label>Barcode</label>
                  <input type="text" class="form-control" v-model="barcode">
              </div>              
              <div class="form-group col-md-3">
                  <label>&nbsp;</label>
                  <button class="btn btn-primary btn-block" v-on:click="addBarcode();">Add barcode</button>                  
              </div>
            </div>
          </div>
          <div class="card">
            <div class="card-body">
              <h4 class="card-title">Main Category</h4>              
              <div class="form-row">
                <span class="label" v-on:click="toggleList(cat,'main')" :class="selectedMainCat == cat ? 'label-primary' : 'label-success'" v-for="cat in mainCategories" :key="cat">{{cat}}</span>
              </div>
            </div>
          </div>
          <div class="card">
            <div class="card-body">
              <h4 class="card-title">Sub-Category</h4>              
              <div class="form-row">
                <span class="label" v-on:click="toggleList(cat,'sub')" :class="selectedSubCats.includes(cat) ? 'label-primary' : 'label-success'" v-for="cat in subCategories" :key="cat">{{cat}}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- </div> -->
</template>

<script>
import { API as AmplifyApi } from 'aws-amplify'
const apiName = 'main';

export default {
  data: () => ({
    query: "",
    game: null,
    barcode: '',
    mainCategories: ['quick','casual','normal','complicated'],
    subCategories: ['strategy','area control','dice rolling','worker placement'],
    selectedMainCat: '',
    selectedSubCats: []
  }),
  watch: {
    query: function() {
	  const vm = this;
	  const querySnap = vm.query;
	  setTimeout(() => {
		  if(querySnap != vm.query){ return; }
		  vm.fetchGames(vm.query);
	  }, 500);
    }
  },
  methods: {
    addBarcode: function(){
      const vm = this;
      vm.$swal({
        imageUrl: 'https://localhost:8080/img/barcode-scan.png',
        imageHeight:300, 
        customClass: 'scannerPopUp',
        input: 'text'
      }).then((result) => {
        if(result.isConfirmed){
          vm.barcode = result.value;
        }
      })
    },
    toggleList: function(elem, cat){
      const vm = this;
      if(cat == 'main'){
        vm.selectedMainCat = elem;
      } else {
        if(vm.selectedSubCats.includes(elem)){
          vm.selectedSubCats.splice(vm.selectedSubCats.indexOf(elem), 1);
        } else {
          vm.selectedSubCats.push(elem);
        }
      }
    },
    getGameInfo: async function(query){
      const vm = this;
      const resp = await AmplifyApi.get(apiName, 'admin/browseBGG/game/'+vm.$route.params.gameId, {});
      console.log(resp);
      vm.game = resp;
    },
    addGame: async function(){
      const vm = this;
      const resp = await AmplifyApi.post(apiName, 'admin/addGame', {
        body: {
          name: vm.game.name,
          description: vm.game.description,
          minPlayers: vm.game.minPlayers,
          maxPlayers: vm.game.maxPlayers,
          year: vm.game.year,
          playtime: vm.game.playingTime,
          mainCategory: vm.selectedMainCat,
          subCategories: vm.selectedSubCats,
          barcode: vm.barcode,
          thumbnail: vm.game.thumbnail,
          image: vm.game.image
        }
      });
      vm.$router.push('/admin/');
    }    
  },
  mounted() {
    this.getGameInfo();
  }
};
</script>